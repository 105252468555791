import React, {Component} from 'react';
import Speech from "./Speech";
import {random} from "../utils/random";
import {generateNewSound} from "../utils/generateNewSound";

class Quiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: null,
            number: null,
            answer: null,
            color: null,
            message: null,
            pause: false,
            range: [10, 100],
            score: {
                wins: 0,
                misses: 0
            },
        }
    }

    checkAnswer = () => {
        let score = {...this.state.score};
        let color, message;

        if (this.state.answer === this.state.number) {
            score.wins++;
            color = 'green-color';
            message = 'correct'
        } else {
            score.misses++;
            color = 'red-color';
            message = 'incorrect'
        }

        this.setState({score, color, message, pause: true});
    }

    setNumber = async () => {
        const min = this.state.range[0];
        const max = this.state.range[1];
        const number = random(min, max);
        const data = await generateNewSound(number);
        this.setState({number, url: data.url, answer: null, pause: false});
    }

    setAnswer = e => {
        const answer = Number(e.target.value);
        this.setState({answer});
    }

    render() {
        return (
            <div className="quiz">
                <div className="results">
                    <span className="green-color">{this.state.score.wins}</span> -
                    <span className="red-color">{this.state.score.misses}</span>
                </div>
                <Speech url={this.state.url}/>
                <div className="options">
                    {
                        this.state.pause?
                            <div onClick={this.setNumber} className="next-button"><span className={this.state.color}>
                                Your answer {this.state.answer} is {this.state.message}!</span><span>&nbsp;Next</span></div>:
                            <><input type="number" onChange={this.setAnswer}/>
                                <button onClick={this.checkAnswer}>OK</button></>
                    }
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.setNumber();
    }
}

export default Quiz;