import './App.css';
import React, {Component} from 'react';
import Quiz from "./components/Quiz";

class App extends Component {
    render() {
        return (
            <Quiz/>
        );
    }
}

export default App;