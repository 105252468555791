import React from 'react';

const Speech = (props) => {
    const audio = new Audio( props.url + '?rand=' + new Date().getTime());

    return (
        <div className="speech">
            <div>
                <button onClick={() => audio.play()} className="material-symbols-outlined speech-buttons">play_circle</button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={() => {
                    audio.pause();
                    audio.currentTime = 0;
                }} className="material-symbols-outlined speech-buttons">stop_circle</button>
            </div>
        </div>
    );
}


export default Speech;